







































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { ApiGetQuestionList } from "@/apis";
import { scaleDesc } from "@/assets/js/scaleDesc.ts";
import { ScaleImageResult } from "@/types";

@Component({
  components: {},
})
export default class ScaleComponent extends Vue {
  radio = "";
  input = "";
  questionLists: any = [];
  scaleDescs = scaleDesc;
  imageScale = {} as ScaleImageResult;
  @Prop() result!: any;
  @Prop({
    default: true,
  })
  displayScores!: boolean;
  @Prop({ default: 0 })
  totalScores!: number;

  mounted() {
    this.getQuestionList();
  }
  getQuestionList() {
    console.log(this.result, "==========this.result");
    ApiGetQuestionList({ module: this.result.module }).then((res) => {
      if (res.success) {
        res.data.forEach((item: any) => {
          if (item.choice_item && typeof item.choice_item === "string") {
            item.choice_item = JSON.parse(item.choice_item);
            item.questionArr = item.question.split("\r\n");
          }
        });
        this.questionLists = res.data;
        if (this.result.module == "222") {
          this.handleImageScore();
        } else {
          this.handleScaleScore();
        }
      }
    });
  }

  handleScaleScore() {
    const scaleResult = this.result.score_detail
      ? JSON.parse(this.result.score_detail)
      : [];
    // 因为答案不确定有多少个, 所有的项都是非必填项, 所以先for答案;
    scaleResult.forEach((item: any) => {
      // for问题;
      this.questionLists.forEach((detail: any, detailIndex: number) => {
        // 通过sid比较是否为同一道大题;
        if (item.sid == detail.sid) {
          // 若为单选题 则直接赋值;
          if (detail.question_type == 102 || detail.question_type == 106) {
            //答案是item,题是detail
            //渲染答案用的choice_name是detail（题的数据）
            //之前是把答案item的choice_name给了detail的chooseScore
            //现在通过下标对应单选
            detail.chooseScore = item.s[0].choice_index[0];
          } else if (
            detail.question_type == 103 ||
            detail.question_type == 104
          ) {
            //答案是item,题是detail
            //先判断这个题有没有答案，如果有找到这个答案
            detail.choice_item.forEach((e: any, i: number) => {
              const que_exist = item.s.find((que: any) => {
                return que.choice_index[0] === i;
              });
              if (que_exist) {
                //该小题的答案应在的位置
                const seat = que_exist.choice_index[1];
                //该位置应有的答案内容
                const result = e.items[seat].choice_name;
                e.chooseScore = result;
              }
            });
          }
        }
      });
    });
  }
  // 蒙特利尔问卷单独做渲染处理;
  handleImageScore() {
    const scaleResult = JSON.parse(this.result.score_detail);
    scaleResult.forEach((item: any, index: number) => {
      if (item.sid == 2) {
        this.imageScale.input1_1 = item.s[0] ? item.s[0].score : "";
      }
      if (item.sid == 3) {
        this.imageScale.input1_2 = item.s[0] ? item.s[0].score : "";
      }
      if (item.sid == 4) {
        this.imageScale.input1_3 = item.s[0] ? item.s[0].score : "";
        this.imageScale.input1_4 = item.s[1] ? item.s[1].score : "";
        this.imageScale.input1_5 = item.s[2] ? item.s[2].score : "";
      }
      if (item.sid == 5) {
        this.imageScale.input2_1 = item.s[0] ? item.s[0].score : "";
        this.imageScale.input2_2 = item.s[1] ? item.s[1].score : "";
        this.imageScale.input2_3 = item.s[2] ? item.s[2].score : "";
      }
      if (item.sid == 6) {
        this.imageScale.input3_1 = item.s[0] ? item.s[0].score : "";
      }
      if (item.sid == 7) {
        this.imageScale.input4_1 = item.s[0] ? item.s[0].score : "";
        this.imageScale.input4_2 = item.s[1] ? item.s[1].score : "";
      }
      if (item.sid == 8) {
        this.imageScale.input5_1 = item.s[0] ? item.s[0].score : "";
      }
      if (item.sid == 9) {
        this.imageScale.input6_1 = item.s[0] ? item.s[0].score : "";
        this.imageScale.input6_2 = item.s[1] ? item.s[1].score : "";
        this.imageScale.input6_3 = item.s[2] ? item.s[2].score : "";
      }
      if (item.sid == 10) {
        this.imageScale.input7_1 = item.s[0] ? item.s[0].score : "";
        this.imageScale.input7_2 = item.s[1] ? item.s[1].score : "";
      }
      if (item.sid == 11) {
        this.imageScale.input8_1 = item.s[0] ? item.s[0].score : "";
      }
      if (item.sid == 12) {
        this.imageScale.input9_1 = item.s[0] ? item.s[0].score : "";
        this.imageScale.input9_2 = item.s[1] ? item.s[1].score : "";
      }
      if (item.sid == 13) {
        this.imageScale.input10_1 = item.s[0] ? item.s[0].score : "";
        this.imageScale.input10_2 = item.s[1] ? item.s[1].score : "";
        this.imageScale.input10_3 = item.s[2] ? item.s[2].score : "";
        this.imageScale.input10_4 = item.s[3] ? item.s[3].score : "";
        this.imageScale.input10_5 = item.s[4] ? item.s[4].score : "";
      }
      if (item.sid == 14) {
        this.imageScale.input11_1 = item.s[0] ? item.s[0].score : "";
        this.imageScale.input11_2 = item.s[1] ? item.s[1].score : "";
        this.imageScale.input11_3 = item.s[2] ? item.s[2].score : "";
        this.imageScale.input11_4 = item.s[3] ? item.s[3].score : "";
        this.imageScale.input11_5 = item.s[4] ? item.s[4].score : "";
        this.imageScale.input11_6 = item.s[5] ? item.s[5].score : "";
      }
    });
    // 深拷贝;
    this.imageScale = JSON.parse(JSON.stringify(this.imageScale));
  }
}
