

















































































// import '@/plugins/print.js'

import { Component, Vue } from "vue-property-decorator";
import ScaleDetailComponent from "@/components/scale/detail.vue";
import { ApiGetScaleDetail } from "@/apis";
import { ScalePatientInfo } from "@/types";
// import print from 'print-js'
@Component({
  components: {
    ScaleDetailComponent,
  },
  filters: {
    parseTimeFilter(value: any) {
      value = parseInt(value);
      const time = new Date(value);
      return `${time.getFullYear()}年${
        (time.getMonth() + 1).toString().length === 1
          ? "0" + (time.getMonth() + 1)
          : time.getMonth() + 1
      }月${
        time.getDate().toString().length === 1
          ? "0" + time.getDate()
          : time.getDate()
      }日  ${
        time.getHours().toString().length === 1
          ? "0" + time.getHours()
          : time.getHours()
      }时:${
        time.getMinutes().toString().length === 1
          ? "0" + time.getMinutes()
          : time.getMinutes()
      }分`;
    },
  },
})
export default class Detail extends Vue {
  //data
  tableData = [];
  // patientInfo = [] as Array<ScalePatientInfo>
  patientInfo: any = {};
  copyVal: any = false;
  //该量表是否有总分
  displayScores = true;
  //量表的总分
  totalScores = 0;
  created() {
    const { id } = this.$route.params;
    ApiGetScaleDetail({ id: id }).then((res) => {
      if (res.success) {
        this.patientInfo = res.data;
        this.tableData = res.data.last_medication
          ? JSON.parse(res.data.last_medication)
          : [];
        const modelId: any = sessionStorage.getItem("case_moduleId");
        this.totalScores = res.data.question_result.score;
        /**
         * 现阶段两个量表没有总分：
         * 侯氏与叶氏 216
         * 还有非运动症状筛查量表（NMSQ）225
         * 如果没有总分，则不显示总分；如果没有作答，则不显示总分。
         */
        if (modelId == 216 || modelId == 225) {
          this.displayScores = false;
        }
        if (!res.data.question_result.score_detail) {
          this.displayScores = false;
        }
      }
    });
  }
  printContext() {
    const printval = this.$refs["print"];
    //隐藏
    this.copyVal = true;
    //定时器
    setTimeout(() => {
      Vue.prototype.$print(printval);
      this.copyVal = false;
    }, 200);
  }
  click_cancel() {
    this.$router.go(-1);
  }
}
